// Import the functions from the SDKs I need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDF2TlAmK4JI6kHbVmUHiDs_GEELS8c-RY",
  authDomain: "wolfstats-cfb9f.firebaseapp.com",
  databaseURL: "https://wolfstats-cfb9f-default-rtdb.firebaseio.com",
  projectId: "wolfstats-cfb9f",
  storageBucket: "wolfstats-cfb9f.appspot.com",
  messagingSenderId: "333749644258",
  appId: "1:333749644258:web:00c21275f171c7a70427bb",
  measurementId: "G-7LFEMYREYS"
};

// Initialize Firebase and Analytics
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);

//Export the initialized Firebase app and Analytics
export { firebaseApp, firebaseAnalytics };